<template>
  <form @submit.prevent="handleSubmit" class="p-5 md:p-20 lg:px-80 xl:px-96">
        <div class="p-5 isolate space-y-1 rounded-md shadow-sm">
            <h1 class="text-4xl mb-5">Register Admin</h1>
            <div class=" relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1" :class="`focus-within:ring-${brand}-600 focus-within:border-${brand}-600`">
            <label for="name" class="block text-xs font-medium text-gray-700">Email</label>
            <input type="email" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" :placeholder="`joe@${url}`" v-model="email" />
            </div>
            <div class="mb-5 relative border border-gray-300 rounded-md rounded-t-none px-3 py-2 focus-within:z-10 focus-within:ring-1" :class="`focus-within:ring-${brand}-600 focus-within:border-${brand}-600`">
            <label for="job-title" class="block w-full text-xs font-medium text-gray-700">Password</label>
            <input type="password" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="********" v-model="password" />
            </div>
            <div v-if="error"> {{ error }}</div>
           <div class="mt-5">
                <button v-if="!isPending" class=" inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 " :class="`bg-${brand}-600 hover:bg-${brand}-700
focus:ring-${brand}-500`">Signup</button>
                <button v-if="isPending" class=" inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 " :class="`bg-${brand}-600 hover:bg-${brand}-700
focus:ring-${brand}-500`">
            Loading
            </button>
           </div>
        </div>

    </form>
</template>

<script>
import useSignup from '@/composables/useSignup'
import { ref } from 'vue'
import configSetup from '@/setup.js'

export default {
    created() {
    const {company} = configSetup()
    document.title = `${company} | Register Admin`
},
    setup() {
        const {brand, company, url} = configSetup()
        const {error, signup, isPending} = useSignup()

        const email = ref('')
        const password = ref('')

        

        const handleSubmit = async () => {
            const res = await signup(email.value, password.value)
            if (!error.value) {
            }
        }

        return{email, password, isPending, error, handleSubmit, brand, company, url}
    }
}
</script>